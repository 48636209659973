import { createApp } from 'vue'
// import router from './router'
import App from './App.vue'
import 'normalize.css'
import 'amfe-flexible'
import 'vant/lib/index.css';
import { Loading } from 'vant';

const app = createApp(App);

app
// .use(Form)
// .use(Field)
// .use(CellGroup)
// .use(Col)
.use(Loading)
// .use(router)

.mount('#app')
